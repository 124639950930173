import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//在main.js引入
import Vant from 'vant'
import 'vant/lib/index.css'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

Vue.use(ElementUI)
Vue.use(Vant)
Vue.config.productionTip = false
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0)
})
new Vue({
	router,
	store,
	ElementUI,
	render: (h) => h(App),
}).$mount('#app')
