import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		redirect: '/index',
		component: HomeView,
		children: [
			{
				path: '/index',
				name: 'index',
				component: () =>
					import(/* webpackChunkName: "about" */ '../views/index.vue'),
			},
			{
				path: '/AboutView',
				name: 'AboutView',
				component: () =>
					import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
			},
			{
				path: '/Service',
				name: 'Service',
				component: () =>
					import(/* webpackChunkName: "about" */ '../views/Service.vue'),
			},
			{
				path: '/Industry',
				name: 'Industry',
				component: () =>
					import(/* webpackChunkName: "about" */ '../views/Industry.vue'),
			},
		],
	},
	{
		path: '/other',
		name: 'other',
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/other.vue'),
	},
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
})

export default router
