<template>
  <div class="home" id="orderFullScreen">
    <!-- 顶部栏 -->
    <div class="head_One" v-if="roroll">
      <div class="center_each">
        <div class="typeimg" @click="typehome">
          <img src="https://genqian.top/img/genqian-pc/logo_icon@2x.png" />
        </div>
        <div class="font_list">
          <div class="tabbar_list">
            <p v-for="(item,index) in pathList" :key="index" class="tab" @click="Jump(item.path,index)"
              :class="activeTabIndex === item.path?'active':''">{{ item.name }}</p>
          </div>
          <div class="menu" @click="To_other"><img src="../icons/caidan_icon@2x.png" alt=""></div>
        </div>
      </div>
    </div>
    <div class="head_One" v-if="rost" style="background-color:rgba(0, 0, 0, 0.548)">
      <div class="center_each">
        <div class="typeimg" @click="typehome"><img src="https://genqian.top/img/genqian-pc/logo_icon@2x.png" />
        </div>
        <div class="font_list">
          <div class="tabbar_list">
            <p v-for="(item,index) in pathList" :key="index" class="tab" @click="Jump(item.path,index)"
              :class="activeTabIndex === item.path?'active':''">{{ item.name }}</p>
          </div>
          <div class="menu" @click="To_other"><img src="../icons/caidan_icon@2x.png" alt=""></div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeTabIndex: '/index',
      pathList: [
        {
          path: '/index',
          name: '首页'
        },
        {
          path: '/AboutView',
          name: '关于亘前'
        },
        {
          path: '/Service',
          name: '业务范围'
        },
        {
          path: '/Industry',
          name: '联系我们'
        }
      ],
      roroll: !false,
      rost: false,
    }
  },
  mounted () {
  },
  created () {
    // if (/Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
    //   navigator.userAgent)) {
    //   window.location.replace("https://company.genqian.top/");
    // }
    this.fullPath(this.$route.path)
  },
  watch: {
    $route (to, from) {
      // 当前页的路由发生变化时，执行需要的操作
      this.fullPath(to.path)
    }
  },
  // 
  methods: {
    fullPath (path) {
      this.activeTabIndex = path
    },
    Jump (item, index) {
      this.$router.push(item)
      this.activeTabIndex = item;
    },
    handleScroll () {
      // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop
      if (scrollTop > 200) {
        this.roroll = false
        this.rost = !false
      } else {
        this.roroll = !false
        this.rost = false
      }
    },
    To_other () {
      this.$router.push('./other')
    },
    typehome () {
      this.$router.push('./index')
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  // 组件销毁前
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },

}

</script> 

<style lang="scss" scoped>
.head_One {
  height: 80px;
  width: 100%;
  // background-color: aquamarine;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 12;
}
.center_each {
  height: 80px;
  width: 90%;
  margin: auto;
  // background-color: rgb(20, 32, 28);
  display: flex;
  justify-content: space-between;
}
.typeimg {
  height: 46px;
  width: 300px;
  // background-color: rgb(27, 37, 34);
  position: relative;
  img {
    height: 46px;
    width: 147px;
    position: absolute;
    top: 20px;
  }
}
.font_list {
  height: 80px;
  width: 500px;
  // background-color: rgb(26, 16, 71);
}
.tabbar_list {
  height: 80px;
  width: 400px;
  // background-color: blue;
  float: left;
  display: flex;
  justify-content: space-around;
  p {
    height: 80px;
    width: 100px;
    display: block;
    line-height: 80px;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.473);
  }
}
.menu {
  height: 80px;
  width: 80px;
  // background-color: rgb(63, 63, 68);
  float: right;
  position: relative;
  cursor: pointer;
  img {
    height: 46px;
    width: 46px;
    position: absolute;
    top: 15px;
    left: 10px;
  }
}
.tab {
  color: white;
}
.active {
  color: white !important;
  font-weight: bold;
}
</style>
